import React from "react";

import "./src/styles/themes/theme.scss";

export const wrapRootElement = ({ element }) => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window !== `undefined`) {
    if (!(`IntersectionObserver` in window)) {
      import(`intersection-observer`);
      console.log(`# IntersectionObserver is polyfilled!`);
    }
    return <>{element}</>;
  }
};
