exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accomodation-tsx": () => import("./../../../src/pages/accomodation.tsx" /* webpackChunkName: "component---src-pages-accomodation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fee-tsx": () => import("./../../../src/pages/fee.tsx" /* webpackChunkName: "component---src-pages-fee-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informations-tsx": () => import("./../../../src/pages/informations.tsx" /* webpackChunkName: "component---src-pages-informations-tsx" */),
  "component---src-pages-materials-index-tsx": () => import("./../../../src/pages/materials/index.tsx" /* webpackChunkName: "component---src-pages-materials-index-tsx" */),
  "component---src-pages-materials-mdx-slug-tsx": () => import("./../../../src/pages/materials/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-materials-mdx-slug-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */)
}

